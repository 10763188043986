<template>
    <div class="count-down">
        <span style="margin-right: 10px;">{{timeText}}</span>
        <span @click="countEvent">{{countDownList}}</span>
    </div>
</template>

<script>
    export default {
        name: "CountDown",
        // props: ['endDialog'],
        props: {
            endDialog: Boolean
        },
        data() {
            return {
                //倒计时
                timeText: '',
                competitionText: '',
                countDownList: '',
                actBeginTime: '',
                actEndTime: '',
                isStart: true,
                competitionType: '',
                countDownInstance: null
            }
        },
        mounted() {
            let time1 = Date.parse(new Date());
            this.getStudentComeInfo().then(res => {
                if (res.code === 200) {
                    this.actEndTime = res.data.competition.end_time;
                    this.actBeginTime = res.data.competition.begin_time;
                    this.competitionType = res.data.competition.type;
                    let time2 = Date.parse(new Date());
                    let time_sub = time2 - time1;
                    let left_time = (Number(this.actEndTime) - Number(res.data.competition.time)) + (time_sub/1000);
                    let before_begin_time = (Number(this.actBeginTime) - Number(res.data.competition.time))+ (time_sub/1000);
                    Number(this.competitionType) === 1 ? this.competitionText = '竞赛' : this.competitionText = '训练';
                    this.countDownInstance = setInterval(() => {
                        if (before_begin_time === 0) {
                            this.countEvent();
                        }
                        if (left_time === 0) {
                            window.location.reload();
                        }
                        left_time--;
                        before_begin_time--;
                        this.countDown(left_time, before_begin_time);
                    }, 1000);
                }
            });


        },
        methods: {
            countEvent() {
                this.$emit('changeStatus', false);
            },
            //获取比赛信息
            getStudentComeInfo() {
                return this.$http.asyncGet(this.$api.studentComInfo, {});
            },
            //倒计时
            timeFormat(param) {
                return param < 10 ? '0' + param : param;
            },
            countDown(left_time, before_begin_time) {
                if (left_time < 0) {
                    // 比赛结束
                    clearInterval(this.countDownInstance);
                }
                if (before_begin_time > 0) {
                    this.timeText = `距离${this.competitionText}开始：`;
                    this.countDownList =  this.$common.formatTimeCount(before_begin_time);
                }
                if (before_begin_time < 0 && left_time > 0) {
                    this.timeText = `距离${this.competitionText}结束：`;
                    this.countDownList = this.$common.formatTimeCount(left_time);
                }
            }
        }
    }
</script>

<style scoped lang="scss">

</style>