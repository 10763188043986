<template>
    <div class="student-container">
        <div class="left-wrapper">
            <div class="left-header">
                <div class="logo">
                    <i class="iconfont">&#xe600;</i>
                </div>
                <p class="theme">店铺装修</p>
            </div>
            <el-scrollbar class="left-menu-wrapper" :native="false">
                <el-menu :default-active="menuActive" mode="vertical" class="left-menu">
                    <el-menu-item index="storeDecorateIndex">
                        <router-link class="nav-item" to="/student/storedecorate/index">
                            <i class="iconfont">&#xe6a4;</i>店招
                        </router-link>
                    </el-menu-item>
                    <el-menu-item index="storeDecorateComputerPoster">
                        <router-link class="nav-item" to="/student/storedecorate/computerposter">
                            <i class="iconfont">&#xe6eb;</i>电脑海报
                        </router-link>
                    </el-menu-item>
                    <el-menu-item index="storeDecorateStoreCategory">
                        <router-link class="nav-item" to="/student/storedecorate/storecategory">
                            <i class="iconfont">&#xe63f;</i>店铺分类
                        </router-link>
                    </el-menu-item>
                    <el-menu-item index="storeDecorateStoreGoods">
                        <router-link class="nav-item" to="/student/storedecorate/storegoods">
                            <i class="iconfont">&#xe728;</i>店铺商品
                        </router-link>
                    </el-menu-item>
                    <el-menu-item index="studentStoreIndex">
                        <router-link class="nav-item" to="/student/store/index" target="_blank">
                            <i class="iconfont">&#xe6e4;</i>预览店铺
                        </router-link>
                    </el-menu-item>
                </el-menu>
            </el-scrollbar>
            <div class="left-download">
                <div class="download-item">
                    <span class="left">店铺装修规则下载</span>
                    <a :href="downFile.rule_file_path" class="right">下载素材</a>
                </div>
                <div class="download-item">
                    <span class="left">店铺装修素材下载</span>
                    <a :href="downFile.sucai_path" class="right">下载素材</a>
                </div>
                <!--                <div class="download-item">-->
                <!--                    <span class="left">店铺商品</span>-->
                <!--                    <a :href="downFile.goods_file_path" class="right">下载素材</a>-->
                <!--                </div>-->
            </div>
        </div>
        <div class="right-wrapper">
            <div class="right-header">
                <div class="countdown">
                    <!--倒计时-->
                    <CountDown></CountDown>
                </div>
                <div class="user-wrapper">
                    <div class="user-info">
                        <div class="user-avatar">
                            <img :src="userIcon ? userIcon : require('../../../assets/images/user_img.png')" alt="" />
                        </div>
                        <div class="user-name">{{username}}</div>
                    </div>
                    <div class="exit-btn" @click="backTo">
                        <i class="iconfont">&#xe642;</i>
                        <span>返回主界面</span>
                    </div>
                </div>
            </div>
            <el-scrollbar class="right-content" :native="false">
                <div class="right-wrapper-content">
                    <router-view/>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
    import CountDown from '../../../components/student/CountDown.vue'

    export default {
        data() {
            return {
                menuActive: '',
                username: '',
                userIcon: '',
                downFile: {
                    // goods_file_path: '',
                    sucai_path: '',
                    rule_file_path: '',
                }
            }
        },
        components: {
            CountDown
        },
        watch: {
            $route(route) {
                this.menuActive = route.matched[1].name;
                let storegoods = ['storeDecorateStoreGoodsAdd'];
                storegoods.map(item=>{
                    if(route.name === item){
                        this.menuActive = 'storeDecorateStoreGoods'
                    }
                })
            },
        },
        mounted () {
            this.username = localStorage.getItem('username');
            let userIcon = localStorage.getItem('headerImg');
            if (userIcon !== 'null') {
                this.userIcon = userIcon;
            }
            this.getDownFile()
        },
        created() {
            this.menuActive = this.$route.matched[1].name;
            let storegoods = ['storeDecorateStoreGoodsAdd'];
            storegoods.map(item=>{
                if(this.$route.name === item){
                    this.menuActive = 'storeDecorateStoreGoods'
                }
            })
        },
        methods: {
            // 获取下载文件路径
            getDownFile() {
                this.$http.axiosGet(this.$api.decorateFileDownload, (res) => {
                    if (res.code === 200) {
                        this.downFile = res.data
                    } else {
                        this.$message.warning(res.msg)
                    }
                }, (err) => {
                    console.log(err)
                })
            },
            // 返回主界面
            backTo() {
                this.$router.push({path: '/student/competition/index', query:{modules: localStorage.getItem('shopModules')}});
            },
        }
    }
</script>

<style scoped lang="scss">
    .student-container {
        background: #F7F9FB;
        width: 100vw;
        height: 100vh;
        display: flex;
        .left-wrapper {
            width: 280px;
            background: #2C405F;
            height: 100%;
            display: flex;
            flex-direction: column;
            font-weight: 500;
            .left-header {
                text-align: center;
                .logo {
                    margin-top: 36px;
                    display: inline-block;
                    width: 60px;
                    height: 60px;
                    background: #FD4446;
                    border-radius: 10px;
                    text-align: center;
                    line-height: 60px;
                    .iconfont {
                        display: block;
                        font-size: 33px;
                        color: #fff;
                    }
                }
                .theme {
                    margin-top: 22px;
                    font-size: 28px;
                    color: #fff;
                    line-height: 1;
                }
            }
            .left-menu-wrapper {
                margin-top: 50px;
                height: 1%;
                flex: 1;
                /deep/ .el-scrollbar__wrap {
                    overflow-x: hidden;
                }
                .left-menu {
                    height: 100%;
                    background: transparent;
                    border-right: none;
                }
                .el-menu-item {
                    height: 60px;
                    line-height: 60px;
                    padding: 0!important;
                    .nav-item {
                        height: 100%;
                        color: #F7F9FB;
                        font-size: 18px;
                        display: flex;
                        align-items: center;
                        padding: 0 20px 0 40px;
                        position: relative;
                        transition: all .3s;
                        .iconfont {
                            color: #F7F9FB;
                            font-size: 24px;
                            margin-right: 20px;
                            transition: all .3s;
                        }
                    }
                    &:focus, &:hover, &.is-active {
                        background-color: #25354F;
                    }
                    &.is-active {
                        &:before {
                            content: '';
                            position: absolute;
                            left: 0;
                            width: 2px;
                            height: 100%;
                            background: #FF4400;
                        }
                    }
                }
            }
            .left-download {
                margin-bottom: 100px;
                color: #fff;
                .download-item {
                    padding: 0 25px 0 40px;
                    line-height: 40px;
                    display: flex;
                    justify-content: space-between;
                    .right {
                        color: #FD4446;
                    }
                }
            }
        }
        .right-wrapper {
            width: 1%;
            flex: 1;
            display: flex;
            flex-direction: column;
            .right-header {
                height: 80px;
                background: #fff;
                border-bottom: 1px solid #D1D2DB;
                box-sizing: border-box;
                display: flex;
                justify-content: center;
                align-items: center;
                position: relative;
                .countdown {
                    font-size: 18px;
                    color: #FD4446;
                    font-weight: 500;
                    .time {
                        margin-left: 10px;
                    }
                }
                .user-wrapper {
                    position: absolute;
                    right: 30px;
                    display: flex;
                    .user-info {
                        display: flex;
                        align-items: center;
                        .user-avatar {
                            width: 32px;
                            height: 32px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            overflow: hidden;
                            border-radius: 50%;
                            border: 1px solid #eee;
                            box-sizing: border-box;
                            img {
                                max-width: 100%;
                                max-height: 100%;
                            }
                        }
                        .user-name {
                            margin-left: 6px;
                        }
                    }
                    .exit-btn {
                        margin-left: 24px;
                        display: flex;
                        align-items: center;
                        cursor: pointer;
                        transition: all .3s;
                        .iconfont {
                            font-size: 20px;
                        }
                        span {
                            margin-left: 6px;
                        }
                        &:hover {
                            color: #1122D8;
                        }
                    }
                }
            }
            /deep/ .right-content {
                height: 1%;
                flex: 1;
                .el-scrollbar__wrap {
                    overflow-x: hidden;
                    .el-scrollbar__view {
                        min-width: 900px;
                        /*height: 100%;*/
                    }
                    .right-wrapper-content {
                        padding: 20px 30px 20px 30px;
                        box-sizing: border-box;
                    }
                }
            }
        }
    }
    /deep/ p {
        margin: 0;
    }
</style>