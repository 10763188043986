const Api = {
    login: 'login',
    templateList: 'template',
    out: 'login/out',
    showKfList:'kefu/showKfList',
    decorateList: 'decorate/listTem',
    categoryList: 'decorate/categoryList',
    decorateUploadFile: 'decorate/uploadFile',
    decorateUploadRule: 'decorate/uploadRule',
    delKfData:'kefu/delKfData',
    createKfData:'kefu/createKfData',
    decorateTemplateList: 'decorate/listTem',
    decorateIssetName: 'decorate/issetName',
    decorateSaveTemplate: 'decorate/saveTem',
    decorateEditTemplate: 'decorate/editTem',
    decorateDelTemplate: 'decorate/delTem',
    college: 'college',
    collegeAdd: 'college/add',
    collegeUpdate: 'college/update',
    collegeUploadLogo: 'college/uploadLogo',
    collegeDel: 'college/delete',
    collegeTemplate: 'college/template',
    saveMG: 'per/saveMG',
    MGList: 'per/MGList',
    delMG: 'per/delMG',
    MRList: 'per/MRList',
    getRG: 'per/getRG',
    saveMR: 'per/saveMR',
    getMR: 'per/getMR',
    delMR: 'per/delMR',
    RGList: 'per/RGList',
    saveRG: 'per/saveRG',
    getAMR: 'per/getAMR',
    saveKfDate:'kefu/saveKfDate',
    showGoods:'kefu/showGoods',
    delGoods:'kefu/delGoods',
    moreUpload:'kefu/moreUpload',
    saveGoods:'kefu/saveGoods',
    fpGoods:'kefu/fpGoods',
    kefuToSql:'kefu/kefuToSql',
    importKefuSql:'kefu/importKefuSql',
    template: 'template',
    collegeAdmin: 'collegeAdmin/admin', // 学校管理员列表
    collegeAdminUploadHead: 'collegeAdmin/uploadHeard', // 学校管理员上传头像
    collegeAdminAdd: 'collegeAdmin/add', // 新增学校管理员
    collegeAdminUpdate: 'collegeAdmin/update', // 修改学校管理员
    collegeAdminDel: 'collegeAdmin/delete', // 删除学校管理员
    trainAdminList: 'trainAdmin/train',
    trainAdminAdd: 'trainAdmin/add',
    trainAdminUploadHead: 'trainAdmin/uploadHeard',
    trainAdminUpdate: 'trainAdmin/update',
    trainAdminDel: 'trainAdmin/delete',
    collegeClass: 'collegeClass',
    collegeClassAdd: 'collegeClass/add',
    collegeClassUpdate: 'collegeClass/update',
    collegeClassDel: 'collegeClass/delete',
    judgesAdminList: 'judgesAdmin/judges',
    judgesAdminUploadHead: 'judgesAdmin/uploadHeard',
    judgesAdminAdd: 'judgesAdmin/add',
    judgesAdminUpdate: 'judgesAdmin/update',
    judgesAdminDel: 'judgesAdmin/delete',
    studentAdminList: 'studentAdmin/student',
    studentAdminUploadHead: 'studentAdmin/uploadHeard',
    studentAdminAdd: 'studentAdmin/add',
    studentAdmin_excel: 'studentAdmin/excel',
    studentAdminUpdate: 'studentAdmin/update',
    studentAdminDel: 'studentAdmin/delete',
    templateAdd:'template/add',
    templateDelete:'template/delete',
    templateUpdate:'template/update',
    comList:'com/comList',
    delCom:'com/delCom',
    classes:'com/classes',
    judges:'judgesAdmin/judges',
    saveCom:'com/saveCom',
    getCom:'com/getCom',
    studentComInfo:'com/info',
    decorateUploadDianzhao: 'decorate/uploadDianzhao',
    decorateDianzhaoInfo: 'decorate/dianzhaoInfo',
    decorateSavaDianzhao: 'decorate/saveDianzhao',
    decorateFileDownload: 'decorate/fileDownload',
    decorateBannerInfo: 'decorate/bannerInfo',
    decorateUploadBanner: 'decorate/uploadBanner',
    decorateSaveBanner: 'decorate/saveBanner',
    decorateClassList: 'decorate/storeClassList',
    decorateDelClass: 'decorate/delClass',
    decorateSaveClass: 'decorate/saveStoreClass',
    decorateDelAllClass: 'decorate/delAllClass',
    listGoods:'decorate/listGoods',
    editCDT:'com/editCDT',
    storeClassList:'decorate/storeClassList',
    uploadGoodsConImg:'decorate/uploadGoodsConImg',
    uploadGoodsImg:'decorate/uploadGoodsImg',
    shopSaveGoods:'decorate/saveGoods',
    editGoods:'decorate/editGoods',
    uploadImg:'market/uploadImg',
    uploadImages:'kefu/uploadImages',
    showStoreMarket:'market/showStoreMarket',
    delStoreMarket:'/market/delStoreMarket',
    storeSelection: 'storeSelection',
    storeSelectionDel: 'storeSelection/delete',
    addMarketGoods:'market/addMarketGoods',
    saveStoreMarket:'market/saveStoreMarket',
    uploadLuyanFile:'decorate/uploadLuyanFile',
    hotKeywordList: 'hk/HKList',
    storeSelectionUploadHead: 'storeSelection/uploadHeard',
    storeSelectionName: 'storeSelection/name',
    goodInfoList: 'hkg/GIList',
    goodInfoDel: 'hkg/delGI',
    judgeList: 'decorate/comList', //比赛列表
    answerList: 'decorate/studentAnswer', //答案列表
    pwModulesInfo: 'decorate/comModel',  //需要评分的模块
    pwScore: 'decorate/studentScore', //教师评分
    previewStore: 'decorate/studentStore',
    previewGoods: 'decorate/goodsInfo',
    decorationModule: 'decorate/comModel',
    decorateGoodInfo: 'decorate/goodsInfo',
    uploadSmFile:'market/uploadSmFile',
    delHotKeyword: 'hk/delHK',
    saveHotKeyword: 'hk/saveHK',
    useOfficialData: 'hk/useOD',
    importHotKeyword: 'hk/importHK',
    booth:'booth',
    data:'booth/data',
    boothDelete:'booth/delete',
    goodInfoUpload: 'hkg/goodMIU',
    goodInfoSave: 'hkg/saveGI',
    goodInfoOne: 'hkg/findGI',
    goodInfoUseOne: 'hkg/useOD',
    getExportHotKeyword: 'hk/getEHK',
    exportHotKeyword: 'hk/exportHK',
    findHotKeyword: 'hk/findHK',
    showOperationList:'operation/showOperation',
    saveOperation:'operation/saveOperation',
    delOperation:'operation/delOperation',
    lyInfo: 'decorate/luyanInfo',
    kefuRobot: 'kefu/oneKfDate',
    resetRobot: 'kefu/resetStuRobot',
    endCompetition: 'com/stopCom',
    dataCentre:'data',
    storeSelectionSupplyList: 'storeSelection/goods',
    storeSelectionAddGoods: 'storeSelection/studentGoods',
    storeSelectionUpdate: 'storeSelection/update/studentGoods',
    storeSelectionEnd: 'storeSelection/end/studentGoods',
    studentStoreInfo:'decorate/storeInfo',
    showStuMarket:'marketStu/showStuMarket',
    searchAdsList:'sd/SDList',
    searchAdsGoods:'operation/getSearchAdvGoods',
    sdUseOfficialData:'sd/useOD',
    generateData:'sd/gData',
    saveSearchData:'sd/saveSD',
    delSearchData:'sd/delSD',
    findSearchData:'sd/findSD',
    saveStudentMarket:'marketStu/saveStuMarket',
    endStudentMarket:'marketStu/endMarketStu',
    delStudentMarket:'marketStu/delStuMarket',
    dataGetZhuangxiuAnswer: 'data/getZhuangxiuAnswer',
    dataPwScore: 'data/pwScore',
    endMarketStu:'marketStu/endMarketStu',
    kefuDone: 'kefu/showKefuStuCom',
    kefuSave: 'kefu/stuPostKefuCom',
    score:'storeDrainage/score',
    kefuGoods: 'kefu/showGoods',
    storeSelectionIndustryData: 'dataAnalysis/storeSelectionIndustryData',
    storeSelectionDataAnalysisGoods: 'dataAnalysis/storeSelectionGoodsData',
    storeSelectionGoodsDetail: 'dataAnalysis/storeSelectionGoodsDetail',
    storeDrainage:'storeDrainage',
    storeDrainageEnd:'storeDrainage/end',
    resources:'storeDrainage/resources',
    crowd:'storeDrainage/crowd',
    position:'storeDrainage/position',
    storeSelectionAmount: 'storeSelection/amount',
    storeSelectionBrandDetail: 'dataAnalysis/storeSelectionBrandDetail',
    storeSelectionHotKeyWord: 'dataAnalysis/storeSelectionHotKeyWord',
    storeDrainageDelete:'storeDrainage/delete',
    SDPlanList:'ep/pnList',
    SDepList:'ep/epList',
    SDepkList:'ep/epkList',
    SDeppList:'ep/eppList',
    SDunList:'ep/unList',
    SDeditTCPD:'ep/editTCPD',
    SDkeywords:'ep/keywords',
    SDsavePromotion:'ep/save',
    SDcheckPN:'ep/checkPN',
    findByKfDate:'kefu/findByKfDate',
    dataLuyanAnswer: 'data/luyanAnswer',
    editOne:'storeDrainage/editOne',
    zhanweiTrendAnalysis: 'dataAnalysis/zhanweiTrendAnalysis',
    zhanweiAreaAnalysis: 'dataAnalysis/zhanweiAreaAnalysis',
    advTrendAnalysis: 'dataAnalysis/advTrendAnalysis',
    advAreaAnalysis: 'dataAnalysis/advAreaAnalysis',
    hotKeyWordAnalysis: 'dataAnalysis/hotKeyWordAnalysis',
    dataComList:'data/comList',
    xlDataList:'data/xlDataList',
    getOneStuData:'data/getOneStuData',
    resetData:'data/resetData',
    storedrainage_endoperation:'storeDrainage/endOperation',
    decorate_trainStoreInfo: 'decorate/TrainStoreInfo',
    decorate_studentZxTrainAnswer: 'decorate/studentZxTrainAnswer',
    decorate_studentLyTrainAnswer: 'decorate/studentLyTrainAnswer',
    ep_getsb: 'ep/getSB',
    ep_goods: 'ep/goods',
    ep_getsd:'ep/getSD',
    ep_people: 'ep/peoples',
    ep_calculatepd: "ep/calculatePD",
    ep_stopep: 'ep/stopEP',
    decorate_zhuangxiuStateList: 'decorate/zhuangxiuStateList',
    decorate_changeZhuangxiuState: 'decorate/changeZhuangxiuState',
    tm_openOrCloseStu: 'TM/openOrCloseStu',
    data_userOpList: 'data/userOpList',
    data_setUserOpList: 'data/setUserOpList',
    data_initializeData: 'data/initializeData',
    storeDrainage_editAmount: 'storeDrainage/editAmount',
    ep_delep: 'ep/delEP',
    ep_editep : 'ep/editEP',
    ep_getepd: 'ep/getEPD',
    ep_editptd: 'ep/editPTD',
    ep_addK: 'ep/addK',
    itemInfo: 'itemInfo',
    itemInfo_delete: 'itemInfo/delete',
    itemInfo_name: 'itemInfo/name',
    ep_delepk: "ep/delEPK",
    ads_keywords: "ads/keywords",
    ep_kwsdata: "ep/kwsData",
    ep_kwsmain: "ep/kwsMain",
    ep_kwmodule: "ep/kwModule",
    ep_peoplecrowd: "ep/peopleCrowd",
    com_startTrain:'com/startTrain',
    oss_setting: 'setting',
    oss_save: 'setting/save',
    data_refresh: 'data/refresh',
    data_export: 'data/export',
    pw_export: 'decorate/exp',
    web: 'web',
    'web_setting': 'setting/settingsave',
    theory_cal: 'data/calculate/',
    storeSettingsUploadMaterial: 'store_settings/upload_material',
    storeSettingsGetCategory: 'store_settings/get_category',
    storeSettingsSave: 'store_settings/save',
    storeSettingsList: 'store_settings/list',
    storeSettingsDetail: 'store_settings/detail',
    storeSettingsDel: 'store_settings/del',
    stuStoreSettingsUploadImg: 'stu_store_settings/upload_img',
    storeSettingsUploadImg: 'store_settings/upload_img',
    live_saleUpload: 'live_sale/upload',
    live_saleOperate: 'live_sale/operate',
    live_saleList: 'live_sale/list',
    live_saleDelete: 'live_sale/delete',
    live_saleInfo: 'live_sale/info',
    stuStoreSettingsAdd: 'stu_store_settings/add',
    stuStoreSettingsTrainDetail: 'stu_store_settings/trainDetail',
    stuStoreSettingsAddStepTwo: 'stu_store_settings/add_step_two',
    stuStoreSettingsAddStepThree: 'stu_store_settings/add_step_three',
    stuStoreSettingsAddStepFour: 'stu_store_settings/add_step_four',
    stuStoreSettingsAddStepFive: 'stu_store_settings/add_step_five',
    stuStoreSettingsStepOneInfo: 'stu_store_settings/step_one_info',
    stuStoreSettingsStepTwoInfo: 'stu_store_settings/step_two_info',
    stuStoreSettingsStepThreeInfo: 'stu_store_settings/step_three_info',
    stuStoreSettingsStepFourInfo: 'stu_store_settings/step_four_info',
    stuStoreSettingsStepFiveInfo: 'stu_store_settings/step_five_info',
    stuStoreSettingsStoreSettingMain: 'stu_store_settings/store_setting_main',
    stuStoreSettingsExamDetail: 'store_settings/exam_detail',
    stuStoreSettingsTemInfo: 'stu_store_settings/tem_info',
    live_saleCopy: 'live_sale/copy',
    studentStoreSelection: "storeSelection/nextCycle",
    studentStoreSalesSummary: "storeSelection/salesSummary",
    studentLiveGetScript: "live_sale_student/script",
    studentLiveGetGoods: "live_sale_student/goods",
    studentLiveGetCoupon: "live_sale_student/coupon",
    studentLiveSubmitAnswer: "live_sale_student/operate_script",
    studentLiveSendCoupon: "live_sale_student/coupon_status",
    studentLiveSendGoods: "live_sale_student/goods_status",
    studentLiveReset: "live_sale/clear",
    studentLiveCheck: "live_sale_student/check",
    studentLiveStop: "live_sale_student/end",
    liveGoodList: 'live_sale_student/goods',
    operateScript: 'live_sale_student/operate_script',
    getScriptInfo: 'live_sale_student/script',
    studentLiveGetBulletChat: "live_sale_student/barrage_list",
    studentLiveRoomDetail: "live_sale_student/room_info",
    live_sale_studentUpload: 'live_sale_student/upload',
    storeDrainage_nextPeriod:'storeDrainage/nextPeriod',
    storeSelectionGoodsRankList:'storeSelection/goodsRankList',
    ep_promotionData:'ep/promotionData',
    studentAdminResetPwd:' studentAdmin/resetPwd'
}

export default Api;