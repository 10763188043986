<template>
    <SchoolAdminLayout v-if="competitionType === 1" />
    <TrainAdminLayout v-else-if="competitionType === 2" />
</template>

<script>
    import SchoolAdminLayout from './SchoolAdminLayout'
    import TrainAdminLayout from './TrainAdminLayout'

    export default {
        name: "Index",
        data() {
            return {
                competitionType: this.$lockr.get('competition_type')
            }
        },
        components: {
            SchoolAdminLayout,
            TrainAdminLayout
        },
    }
</script>

<style scoped lang="scss">

</style>