import { render, staticRenderFns } from "./CommodityListLayout.vue?vue&type=template&id=f75e9496&scoped=true"
import script from "./CommodityListLayout.vue?vue&type=script&lang=js"
export * from "./CommodityListLayout.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f75e9496",
  null
  
)

export default component.exports