<template>
    <div class="theory-content">
        <el-tabs
                v-model="activeName"
                type="card"
                class="tabs-full"
                @tab-click="init">
            <el-tab-pane label="理论管理" name="first">
            </el-tab-pane>
            <el-tab-pane label="问答评分" name="second">
            </el-tab-pane>
        </el-tabs>
        <div class="router-content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                activeName: "first",
                showCreateForm: false,
                editForm: "",
                Pid: "",
                answerData: [],
            };
        },
        created() {
            if(this.$route.meta.tab && this.$route.meta.tab == 1){
                this.activeName = 'second'
            }
        },
        methods: {
            showDetail(val) {
                this.showCreateForm = !this.showCreateForm;
                this.editForm = "";
            },
            changeId(val) {
                this.editForm = val;
                this.showCreateForm = !this.showCreateForm;
            },
            getProblemListId(arg) {
                this.Pid = arg;
            },
            getAnswerListData(arg) {
                this.Pid = "";
                this.answerData = arg;
                console.log(this.answerData);
            },
            init() {
                if (this.activeName == 'first') {
                    this.$router.push('/trainAdmin/theoryManage/index')
                } else {
                    this.$router.push('/trainAdmin/problemAnalysis/index')
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .theory-content {
        height: 100%;
        display: flex;
        flex-direction: column;

        .education-content {
            flex: 1;
            height: 1%;
        }

        .router-content {
            flex: 1;
            height: 1%;
            background: #fff;
        }
    }
</style>