<template>
    <el-tabs v-model="decorationTab" type="card" class="education-content tabs-full">
        <el-tab-pane label="竞赛模板管理" name="competition">
            <router-view />
        </el-tab-pane>
    </el-tabs>
</template>

<script>
    export default {
        data() {
            return {
                decorationTab: 'competition',
            }
        }
    }
</script>

<style scoped lang="scss">
    .education-content {
        height: 100%;
    }
</style>