<template>
  <div class="register-layout">
    <div class="header-box">
      <div class="title">网店规划与注册</div>
      <CountDown></CountDown>
      <div class="user-wrapper">
        <div class="user-info">
          <div class="user-avatar">
            <img :src="userIcon ? userIcon : require('../../../assets/images/user_img.png')" alt="" />
          </div>
          <div class="user-name">{{username}}</div>
        </div>
        <div class="exit-btn" @click="backTo">
          <i class="iconfont">&#xe642;</i>
          <span>提交{{examType === '1' ? '考试' : '训练'}}，并返回主界面</span>
        </div>
      </div>
    </div>
    <div class="content-wrapper">
      <el-scrollbar class="content-box">
        <div class="content">
          <router-view />
        </div>
      </el-scrollbar>
    </div>
  </div>
</template>

<script>
import CountDown from '../../../components/student/CountDown.vue'
export default {
  data() {
    return {
      username: localStorage.getItem('username'),
      userIcon: localStorage.getItem('headerImg'),
      examType: localStorage.getItem('type')
    }
  },
  components: {
    CountDown
  },
  mounted() {

  },
  methods: {
    // 返回主界面
    backTo() {
      this.$router.push({path: '/student/competition/index', query:{modules: localStorage.getItem('shopModules')}});
    },
  }
}
</script>

<style scoped lang="scss">
.register-layout {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.content-wrapper {
  height: 1%;
  flex: 1;
  background: #f7f9fb;
  padding: 20px 30px 20px 40px;
}
.content-box {
  height: 100%;
  :deep(& > .el-scrollbar__wrap) {
    overflow-x: hidden;
    .el-scrollbar__view {
      //height: 100%;
    }
  }
}
.content {
  background: #fff;
  min-width: 1000px;
}
.header-box {
  display: flex;
  height: 80px;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  background: #fff;
  border-bottom: 1px solid #D1D2DB;
  .title {
    width: 268px;
    font-size: 18px;
    font-weight: 500;
  }
}
.user-wrapper {
  display: flex;
  .user-info {
    display: flex;
    align-items: center;
    .user-avatar {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      overflow: hidden;
      border-radius: 50%;
      border: 1px solid #eee;
      box-sizing: border-box;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
    .user-name {
      margin-left: 6px;
    }
  }
  .exit-btn {
    margin-left: 24px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: all .3s;
    .iconfont {
      font-size: 20px;
    }
    span {
      margin-left: 6px;
    }
    &:hover {
      color: #1122D8;
    }
  }
}
</style>