<template>
    <div class="dataCenter">
        <el-tabs
                v-model="activeName"
                type="card"
                class="tabs-full"
                @tab-click="init">
            <el-tab-pane label="得分榜单" name="scoreList">
            </el-tab-pane>
            <el-tab-pane label="理论榜单" name="theoryTop" v-if="role == 2" @change="init">
            </el-tab-pane>
        </el-tabs>
        <div class="router-content">
            <router-view></router-view>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DataCenterLayout",
        data() {
            return {
                activeName: "scoreList",
                role: null,
                trainInfo: {},
            };
        },
        created() {
            let role = localStorage.getItem("role");
            this.role = role;
            if(this.$route.meta.tab && this.$route.meta.tab == 1){
                this.activeName = 'theoryTop'
            }
        },
        methods: {
            init() {
                if(this.activeName =='scoreList'){
                    this.$router.push('/trainadmin/datacenter/scoreList')
                } else {
                    this.$router.push('/trainadmin/datacenter/theoryList')
                }
            },
        },
    };
</script>

<style scoped lang="scss">
    .dataCenter {
        height: 100%;
        display: flex;
        flex-direction: column;

        .education-content {
            flex: 1;
            height: 1%;
        }

        .router-content {
            flex: 1;
            height: 1%;
            background: #fff;
        }
    }
</style>